import React from "react";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { StaticQuery, graphql } from "gatsby";
import Header from "./molecules/header";
import Footer from "./molecules/footer";
import { Row, Col, Container } from "reactstrap";
import styled from "styled-components";
import "./layout.css";
import Navigation from "./molecules/navigation";
import { Trans } from "react-i18next";
import home from "../images/Home.png";
import { getSN } from "../services/auth";
import Notification from "./atoms/notification";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
`;
const Content = styled(Container)`
  /*margin-top: 79px;*/
  max-width: 1500px !important;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #d7d7d7;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
`;
const MainText = styled.div`
  font-size: 41px;
  text-align: center;
  padding: 7px 0;
  background-color: #e5e5e5;
  border-top: 1px solid #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
  }
  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
`;

const PageContent = styled(Col)`
  border-top: 15px solid transparent;
  border-bottom: none;
  padding-top: 15px;
`;

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <Wrapper>
            <Header
              logoLink="/library"
              siteTitle={data.site.siteMetadata.title}
              showNav={false}
            />

            <Content>
              <Notification timeToClose={5000}></Notification>
              <Row>
                <img
                  src={home}
                  style={{ marginBottom: 0, padding: 0, minHeight: "120px" }}
                  alt="Home"
                  placeholder="none"
                />
                <MainText>
                  <Trans>PerkinElmer Gas Chromatography Resource Center</Trans>{" "}
                </MainText>
              </Row>
              {getSN() !== "" && <Navigation></Navigation>}
              <Row>
                <PageContent
                  style={{
                    borderTopColor: this.props.color
                      ? this.props.color
                      : "transparent",
                  }}
                >
                  {this.props.children}
                </PageContent>
              </Row>

              <Footer></Footer>
            </Content>
          </Wrapper>
        )}
      />
    );
  }
}

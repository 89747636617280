import React from "react";
import NavLink from "../atoms/navLink";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Row } from "reactstrap";
import { faBook, faFileAlt, faStore } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { COA_COLOR, LIBRARY_COLOR, STORE_COLOR } from "../../theme/default";
import { Trans } from "react-i18next";
import { getPN } from "../../services/auth";

const NavItemContents = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    padding: 10px 20px;
    z-index: 99;
    visibility: hidden;
  opacity: 0;
  transition: visibility 0s 300ms, opacity 300ms linear;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    color: white;
`;

const Navigation = () => {
  var url = getPN() ? `https://www.perkinelmer.com/product/${getPN()}` :  "https://www.perkinelmer.com/buy";
  return (
  <Row>
    <NavLink to="/coa" color={COA_COLOR} icon={faFileAlt} text={"Retrieve CoA"}>
      <NavItemContents style={{backgroundColor: COA_COLOR }} className="menu"><Trans>View your column CoA</Trans></NavItemContents>
    </NavLink>
    <NavLink
      to="/library"
      color={LIBRARY_COLOR}
      icon={faBook}
      text={"Technical Library"}
    >
      <NavItemContents style={{backgroundColor: LIBRARY_COLOR}}  className="menu"><Trans>Review Applications, Videos, our GC Column Finder, and other GC Consumables Literature Begin Your Journey</Trans></NavItemContents>
    </NavLink>
    <NavLink
      href={url}
      color={STORE_COLOR}
      icon={faStore}
      text={"Buy Online"}
    >
      <NavItemContents  style={{backgroundColor: STORE_COLOR}} className="menu"><Trans>Quickly Place a New Order, Discover New Products and other Consumables for your Instruments</Trans></NavItemContents>
    </NavLink>
  </Row>
)};

export default Navigation;

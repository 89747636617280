import React from 'react'
import styled, { css } from 'styled-components'


const styles = css`
  font-family: 'Roboto', "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-decoration: underline;
`

const Anchor = styled.a`${styles}`

const LinkExternal = ({ ...props }) => {
  return <Anchor target='_blank' {...props} />
}

export default LinkExternal
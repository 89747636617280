import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import {Link} from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkExternal from "./LinkExternal";
import { Trans } from "react-i18next";

const StyledContainer = styled(Col)`
  padding: 0;
  text-align: center;
  &:hover .menu {
    visibility: visible;
    opacity: 1;
    transition: opacity 300ms linear;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const StyledLink = styled(Link)`
  display: block;
  color: white !important;
  padding: 10px 0;
  text-decoration: none;
`;

const StyledLinkExternal = styled(LinkExternal)`
  display: block;
  color: white !important;
  padding: 10px;
  text-decoration: none;
  &:hover {
    color: white !important;
    cursor: pointer;
  }
  
`
const NavLink = ({ ...props }) => {
  return (
    <StyledContainer style={{ backgroundColor: props.color }}>
      {props.href && 
       <StyledLinkExternal  {...props}>
          <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>&nbsp;<Trans>{props.text}</Trans>
       </StyledLinkExternal>
      }
      {!props.href && 
        <StyledLink {...props}>
          <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>&nbsp;<Trans>{props.text}</Trans>
        </StyledLink>
      }
      <div>{props.children}</div>
    </StyledContainer>
  );
};

export default NavLink;

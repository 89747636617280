import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/atoms/seo";
import { COA_COLOR } from "../theme/default";
import Center from "../components/atoms/center";
import { GenerateCofADoc } from "../services/coa";
import GCColumnPerformanceSummary from "../components/organisms/pdfs/gCColumnPerformanceSummary";
import { Button } from "reactstrap";
import { getSN } from "../services/auth";
import { withTranslation } from 'react-i18next';

class CoA extends React.Component {

  download = () => {
    GenerateCofADoc(getSN(), "en").then((result) => {
      if (result.err) {
      } else {
        var title = "CofA-SN" + this.state.sn + "-PN" + this.state.pn + ".pdf";
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveBlob(new Blob([result]), title);
        } else {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      }
      this.setState({ loading: false });
    });

  }
  
  render() {
    return (
      <Layout color={COA_COLOR}>
        <Seo title={this.props.t('CoA')} keywords={[`PerkinElmer`]} />
       
          <GCColumnPerformanceSummary site={true}></GCColumnPerformanceSummary>
          <Center>
          <Button style={{display: "none"}} onClick={this.download}>Download</Button>
        </Center>
      </Layout>
    );
  }
}
export default withTranslation()(CoA);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "coa"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
